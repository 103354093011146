<template>
  <AssinaturaV2 v-if="clubVersion === 'v2'"/>
  <div class="container-grid2"  v-else>
    <Assinatura />
  </div>
</template>

<script>
export default {
  components: {
    Assinatura: () => import("@/components/Assinatura.vue"),
    AssinaturaV2: () => import("@/components/V2/Assinatura.vue"), 
  },
  data() {
    return {
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.container-grid {
  display: grid;
  grid-template-columns: 100px 1fr;
}

.container-grid2 {
  width: 100%;
}

.slidebarMobile {
  float: right;
  padding-top: 20px;
  position: relative;
  right: 0.6em;
}

.containerx {
  width: 98%;
}
</style>
